import { ASSET_IDS } from "../constants/constants";
export const basketTokens = (assetName) => {
  const goUSDbasketTokens = [
    {
      name: "USDC",
      displayName: "USDC",
      blockchain: "Algorand",
      isAssetOPTinRequired: true,
      assetID: ASSET_IDS["USDC"],
    },

    {
      name: "XUSD",
      displayName: "XUSD",
      blockchain: "Algorand",
      isAssetOPTinRequired: true,
      assetID: ASSET_IDS["XUSD"],
    },

    // {
    //   name: "WDAI",
    //   blockchain: "Algorand",
    //   isAssetOPTinRequired: true,
    //   assetID: ASSET_IDS["WDAI"],
    // },
    {
      name: "goUSD",
      displayName: "goUSD",
      blockchain: "Algorand",
      isAssetOPTinRequired: true,
      assetID: ASSET_IDS["goUSD"],
    },
  ];

  const goETHbasketTokens = [
    {
      name: "wETH",
      displayName: "wETH",
      blockchain: "Algorand",
      isAssetOPTinRequired: true,
      assetID: ASSET_IDS["wETH"],
    },
    {
      name: "goETH",
      displayName: "goETH",
      blockchain: "Algorand",
      isAssetOPTinRequired: true,
      assetID: ASSET_IDS["goETH"],
    },
  ];
  const goBTCBasketTokens = [
    {
      name: "wBTC",
      displayName: "wBTC",
      blockchain: "Algorand",
      isAssetOPTinRequired: true,
      assetID: ASSET_IDS["wBTC"],
    },
    {
      name: "goBTC",
      displayName: "goBTC",
      blockchain: "Algorand",
      isAssetOPTinRequired: true,
      assetID: ASSET_IDS["goBTC"],
    },
  ];

  switch (assetName) {
    case "goUSD":
      return goUSDbasketTokens;
      // eslint-disable-next-line
      break;
    case "goBTC":
      return goBTCBasketTokens;
      break;
    case "goETH":
      return goETHbasketTokens;
      break;
    default:
      return [];
  }
};
