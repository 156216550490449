import { createContext, useState } from "react";

const NavigationDrawerContext = createContext();

const NavigationDrawerProvider = ({ children }) => {
  const [toggle, setToggleDrawer] = useState(false);
  const changeDrawerState = () => {
    setToggleDrawer((prev) => !prev);
  };
  return (
    <NavigationDrawerContext.Provider value={{ toggle, changeDrawerState }}>
      {children}
    </NavigationDrawerContext.Provider>
  );
};

export { NavigationDrawerContext, NavigationDrawerProvider };
